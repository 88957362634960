import React, { useEffect } from 'react'
import './ServicesPage.css'
import { useTranslation } from 'react-i18next';
import TechStack from '../ServicesPage/TechnologiesStack/TechStack';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ServicesPage() {
  const { t } = useTranslation();
  const location = useLocation();

  // Scroll to the section based on hash fragment
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <div className='sections-spacing'>

      <Helmet>
        <title>LD InterTech Innovation - Services</title>
        <link rel="canonical" href="https://ldintertech.com/services" />
        <meta name="description" content="LD InterTech Innovation delivers innovative technology and marketing solutions, offering expert services in hardware, software, cloud systems, and digital marketing to help businesses succeed in the digital age." />
        <meta name="keywords" content="technology and marketing solutions, digital transformation services, innovative technology consulting, cloud system integration, hardware and software development, custom cloud technologies, business digital transformation, cutting-edge marketing strategies, digital marketing for businesses, future-focused business solutions, advanced hardware solutions, software solutions for enterprises, data-driven marketing, tech-driven business growth, custom software and cloud solutions, innovative digital solutions, technology consulting for businesses, smart cloud services, market positioning strategies, business innovation and technology, cloud integration experts, digital strategy consulting, end-to-end tech solutions, business transformation experts" />
        <meta name="author" content="LD InterTech Innovation" />
        <meta name="robots" content="index, follow" />
        <html lang="en" />
      </Helmet>
      <div className='service-page-sec'>
        <h2 className='service-page-heading'>{t('ServicePage.Heading')}</h2>
      </div>
      <TechStack />
    </div>
  )
}

export default ServicesPage