// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZCuJqp67zKm8OAUqsWiXzrcCX6i6FEd4",
  authDomain: "contact-form-215af.firebaseapp.com",
  projectId: "contact-form-215af",
  storageBucket: "contact-form-215af.appspot.com",
  messagingSenderId: "98708543211",
  appId: "1:98708543211:web:78abb32eb658a1fa7550ae"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app)