import React from 'react'
import './AboutPage.css'
import { useTranslation } from 'react-i18next';
import Frame1 from '../../assets/HomePage/Transform_3D.png';
import OurValues from './OurValues/OurValues';
import { Helmet } from 'react-helmet';

const teamMembers = [
  { name: 'Manoj Babu', title: 'UX/UI Designer', image: Frame1 },
  { name: 'Anitha', title: 'Full Stack Developer', image: Frame1 },
  { name: '', title: '', image: Frame1 }
];

function AboutPage() {
  const { t } = useTranslation();

  return (
    <div className='sections-spacing'>

      <Helmet>
        <title>LD InterTech Innovation - About</title>
        <link rel="canonical" href="https://ldintertech.com/about" />
        <meta name="description" content="LD InterTech Innovation delivers innovative technology and marketing solutions, offering expert services in hardware, software, cloud systems, and digital marketing to help businesses succeed in the digital age." />
        <meta name="keywords" content="technology and marketing solutions, digital transformation services, innovative technology consulting, cloud system integration, hardware and software development, custom cloud technologies, business digital transformation, cutting-edge marketing strategies, digital marketing for businesses, future-focused business solutions, advanced hardware solutions, software solutions for enterprises, data-driven marketing, tech-driven business growth, custom software and cloud solutions, innovative digital solutions, technology consulting for businesses, smart cloud services, market positioning strategies, business innovation and technology, cloud integration experts, digital strategy consulting, end-to-end tech solutions, business transformation experts" />
        <meta name="author" content="LD InterTech Innovation" />
        <meta name="robots" content="index, follow" />
        <html lang="en" />
      </Helmet>
      <div className='service-page-sec'>
        <h2 className='service-page-heading'>{t('AboutPage.Heading')}</h2>
      </div>

      <div className='margin-div-sec'></div>

      <div className='homepage-about-section'>
        <div className='about-section-div'>
          <h2 className='homepage-about-heading'>{t('AboutPage.WhoWeAre')}<span style={{ fontStyle: "normal", paddingLeft: "6px" }}> {t('AboutPage.WhoWeAre1')}</span></h2>
        </div>
        <div className='about-section-div1'>
          <p className='homepage-about-text'>{t('AboutPage.WhoWeAreText1')}</p>
        </div>
      </div>


      <div className='homepage-about-section'>
        <div className='about-section-div'>
          <h2 className='homepage-about-heading'>{t('AboutPage.WhyUs')}<span style={{ fontStyle: "normal", paddingLeft: "6px" }}> {t('AboutPage.WhyUs1')}</span></h2>
        </div>
        <div className='about-section-div1'>
          <p className='homepage-about-text'>{t('AboutPage.WhyUsText1')}</p>
        </div>
      </div>

      {/* <div className='homepage-services-section'>
        <h2 className='homepage-about-heading'>{t('AboutPage.Team')}<span style={{ fontStyle: "normal", paddingLeft: "8px" }}> {t('AboutPage.Team1')}</span></h2>
        <div className='our-services-section'>
          <div className='services-grid'>
            {teamMembers.map((member, index) => (
              <div key={index}>
                <div className='service-card'>
                  <div className='card-content'>
                  </div>
                </div>
                <h2 className='team-member-name'>{member.name}</h2>
                <p className='team-member-title'>{member.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div> */}



      <div className='homepage-services-section about-sec'>
        <h2 className='homepage-about-heading'>{t('AboutPage.Values')}<span style={{ fontStyle: "normal", paddingLeft: "12px" }}>{t('AboutPage.Values1')}</span></h2>
        <OurValues />
      </div>
    </div>
  )
}

export default AboutPage