import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-section sections-spacing'>
      <div>
        <p className='footer-sec-text'>© 2024. All Rights Reserved</p>
      </div>
      <div>
        <p className='footer-sec-text'>Privacy Policy</p>
      </div>
    </div>
  )
}

export default Footer