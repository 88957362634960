import React from 'react';
import './OurServices.css';
import Frame1 from '../../../assets/HomePage/Transform_3D.png';
import Frame2 from '../../../assets/HomePage/Flip_3D.png';
import Frame3 from '../../../assets/HomePage/Bezier Tool_3D.png';
import Frame4 from '../../../assets/HomePage/Blend Tool_3D.png';
import Frame5 from '../../../assets/HomePage/Exclude Pathfinder_3D.png';
import Frame6 from '../../../assets/HomePage/Adjuster_3D.png';
import { useNavigate } from 'react-router-dom';

function OurServices() {
  const navigate = useNavigate();

  const services = [
    { name: 'UX/UI Design', image: Frame1, section: 'ux-ui-design' },
    { name: 'Graphic Design', image: Frame2, section: 'graphic-design' },
    { name: 'Full Stack Development', image: Frame3, section: 'full-stack-development' },
    { name: 'Digital Marketing', image: Frame4, section: 'digital-marketing' },
    { name: 'Embedded Systems', image: Frame5, section: 'embedded-systems' },
    { name: 'Mechanical Design', image: Frame6, section: 'mechanical-design' },
  ];

  const handleServiceClick = (section) => {
    navigate(`/services#${section}`);
  };

  return (
    <div className='our-services-section'>
      <div className='services-grid'>
        {services.map((service, index) => (
          <div key={index} className='service-card' onClick={() => handleServiceClick(service.section)}>
            <div className='card-content'>
              <h3 className='service-title'>{service.name}</h3>
              <button className='service-button'>
                <span className='service-arrow'>&#8599;</span>
              </button>
            </div>
            <img src={service.image} alt={service.name} className='service-icon' />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurServices;
