import React, { useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FaBars, FaTimes } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import './Header.css'
import Logo from '../../assets/LOGO.png'

function Header() {
    const location = useLocation();

    const [isNavbarOpen, setNavbarOpen] = useState(false);
    // Function to determine if a NavLink should be active based on the provided path
    const isNavLinkActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className='header-section sections-spacing'>
            <Navbar expand="md">
                <Container fluid>
                    <Navbar.Brand href="/" className='main-logo'>
                        <img className='logo-section' alt='website' src={Logo} />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        onClick={() => setNavbarOpen(!isNavbarOpen)}
                    >
                        {isNavbarOpen ? <FaTimes className='toggle-icon-sec' /> : <FaBars className='toggle-icon-sec' />}
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-end flex-grow-1">
                            <Nav.Link href="/home" className={`nav-items-text not-active ${isNavLinkActive('/home') || isNavLinkActive('/') ? 'active' : ''}`}>Home</Nav.Link>
                            <Nav.Link href="/services" className={`nav-items-text not-active ${isNavLinkActive('/services') ? 'active' : ''}`}>Services</Nav.Link>
                            <Nav.Link href="/about" className={`nav-items-text not-active ${isNavLinkActive('/about') ? 'active' : ''}`}>About</Nav.Link>
                            <Nav.Link href="/contact" className={`nav-items-text1 not-active ${isNavLinkActive('/contact') ? 'active' : ''}`}>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header