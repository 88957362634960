import React from 'react'
import './OurWorks.css'
import { useTranslation } from 'react-i18next';
import Porject1 from '../../../assets/HomePage/Frame 38.png'
import Porject2 from '../../../assets/HomePage/Frame 39.png'

function OurWorks() {
    const { t } = useTranslation();

    return (
        <div className='work-section-div'>
            <div className='work-sec-div'>
                <img src={Porject1} className='work-sec-img' alt='' />
                <h2 className='work-heading-sec'>{t('MainPage.Project1')}</h2>
            </div>
            <div className='work-sec-div'>
                <img src={Porject2} className='work-sec-img' alt='' />
                <h2 className='work-heading-sec'>{t('MainPage.Project2')}</h2>
            </div>
        </div>
    )
}

export default OurWorks