import React from 'react'
import './OurValues.css'
import { useTranslation } from 'react-i18next';
import Image1 from '../../../assets/AboutPage/Value1.png'
import Image2 from '../../../assets/AboutPage/Value2.png'
import Image3 from '../../../assets/AboutPage/Value3.png'

function OurValues() {
    const { t } = useTranslation();

    return (
        <div>
            <hr className='about-hr' />
            <div className='about-values-sec'>
                <div className='about-value-sec-div'>
                    <h2 className='team-member-name'>{t('AboutPage.ValueHeading1')}</h2>
                    <p className='team-member-title about-subsec'>{t('AboutPage.ValuesText1')}</p>
                </div>
                <div className='about-value-sec-div'>
                    <img src={Image1} className='about-sec-img' alt='adaptable' />
                </div>
            </div>
            <hr className='about-hr' />
            <div className='about-values-sec'>
                <div className='about-value-sec-div'>
                    <h2 className='team-member-name'>{t('AboutPage.ValueHeading2')}</h2>
                    <p className='team-member-title about-subsec'>{t('AboutPage.ValuesText2')}</p>
                </div>
                <div className='about-value-sec-div'>
                    <img src={Image2} className='about-sec-img' alt='adaptable' />
                </div>
            </div>
            <hr className='about-hr' />
            <div className='about-values-sec'>
                <div className='about-value-sec-div'>
                    <h2 className='team-member-name'>{t('AboutPage.ValueHeading3')}</h2>
                    <p className='team-member-title about-subsec'>{t('AboutPage.ValuesText3')}</p>
                </div>
                <div className='about-value-sec-div'>
                    <img src={Image3} className='about-sec-img' alt='adaptable' />
                </div>
            </div>
        </div>
    )
}

export default OurValues