import React, { useState } from 'react'
import './ContactPage.css'
import Vector from '../../assets/graphic-download.svg'
import { firestore } from './firebaseConfig'; // Firebase configuration
import { collection, addDoc } from "firebase/firestore";
import { Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlinePhone } from "react-icons/md";


function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        // Add form data to Firestore in the 'contacts' collection
        await addDoc(collection(firestore, "contacts"), formData);
        setSubmitSuccess(true); // Set success state
        setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form
        setErrors("");
      } catch (e) {
        console.error("Error adding document: ", e);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <div className='contact-section'>

        <Helmet>
          <title>LD InterTech Innovation - Contact</title>
          <link rel="canonical" href="https://ldintertech.com/contact" />
          <meta name="description" content="LD InterTech Innovation delivers innovative technology and marketing solutions, offering expert services in hardware, software, cloud systems, and digital marketing to help businesses succeed in the digital age." />
          <meta name="keywords" content="technology and marketing solutions, digital transformation services, innovative technology consulting, cloud system integration, hardware and software development, custom cloud technologies, business digital transformation, cutting-edge marketing strategies, digital marketing for businesses, future-focused business solutions, advanced hardware solutions, software solutions for enterprises, data-driven marketing, tech-driven business growth, custom software and cloud solutions, innovative digital solutions, technology consulting for businesses, smart cloud services, market positioning strategies, business innovation and technology, cloud integration experts, digital strategy consulting, end-to-end tech solutions, business transformation experts" />
          <meta name="author" content="LD InterTech Innovation" />
          <meta name="robots" content="index, follow" />
          <html lang="en" />
        </Helmet>
        <div>
          <img src={Vector} className='contctpage-sec-img' alt='contact' />
        </div>
        <div className="contact-form-container">
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'input-error' : ''}
                required
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">E-mail *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'input-error' : ''}
                required
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="If you have something to tell.."
                value={formData.message}
                onChange={handleChange}
                rows="6"
              />
            </div>

            {/* <button type="submit" className="submit-btn">Submit</button> */}
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                  />
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>

        {/* Toast notification for form submission success */}
        <ToastContainer position="top-end" className="p-3" style={{ position: "fixed" }}>
          <Toast onClose={() => setSubmitSuccess(false)} show={submitSuccess} delay={3000} autohide className="bg-success text-white">
            <Toast.Header>
              <strong className="me-auto">Success</strong>
            </Toast.Header>
            <Toast.Body>Form Submitted Successfully!</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <div className="sections-spacing last-sec-conn">
        <div className="sec-contact">
          <Container className="contact-box">
            <FaEnvelope className="contact-icon" />
            <span className="contact-text"><a href="mailto:info@ldintertech.com" style={{ textDecoration: "none", color: "#A9A9A9" }}>info@ldintertech.com</a></span>
          </Container>

          <Container className="contact-box">
            <MdOutlinePhone className="contact-icon" />
            <span className="contact-text">+91 9392031593</span>
          </Container>
        </div>

        <div className="last-sec-connect last-medis">
          <Container className="contact-box">
            <FaMapMarkerAlt className="contact-icon" />
            <span className="contact-text">
              M/S Proactive Plug and Play <br />
              40-1/1-30 A, Laxmi Nagar, Mogalrajpuram,<br />
              Vijayawada, Andhra Pradesh.<br/>
              Pin: 520010
            </span>
          </Container>
        </div>
      </div>

    </>
  )
}

export default ContactPage