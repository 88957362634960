import React from 'react';
import './TechStack.css';
import Tech1 from '../../../assets/ServicePage/Stack1.png';
import Tech2 from '../../../assets/ServicePage/Stack2.png';
import Tech3 from '../../../assets/ServicePage/Stack3.png';
import Tech4 from '../../../assets/ServicePage/Stack4.png';
import Tech5 from '../../../assets/ServicePage/Stack5.png';
import Tech6 from '../../../assets/ServicePage/Stack6.png';
import { useTranslation } from 'react-i18next';

function TechStack() {
    const { t } = useTranslation();

    const techCards = [
        {
            id: 'ux-ui-design',
            heading: 'MainPage.UX/UIDesign',
            description: 'ServicePage.Tech1Text',
            tags: [
                'ServicePage.Tech1Stach1',
                'ServicePage.Tech1Stach2',
                'ServicePage.Tech1Stach3',
                'ServicePage.Tech1Stach4',
                'ServicePage.Tech1Stach5',
                'ServicePage.Tech1Stach6',
                'ServicePage.Tech1Stach7',
                'ServicePage.Tech1Stach8',
            ],
            imageSrc: Tech1,
            backgroundColor: '#232323',
        },
        {
            id: 'graphic-design',
            heading: 'MainPage.GraphicDesign',
            description: 'ServicePage.Tech2Text',
            tags: [
                'ServicePage.Tech2Stach1',
                'ServicePage.Tech2Stach2',
                'ServicePage.Tech2Stach3',
                'ServicePage.Tech2Stach4',
                'ServicePage.Tech2Stach5',
                'ServicePage.Tech2Stach6',
            ],
            imageSrc: Tech2,
            backgroundColor: '#232323',
        },
        {
            id: 'full-stack-development',
            heading: 'MainPage.FullStackDevelopment',
            description: 'ServicePage.Tech3Text',
            tags: [
                'ServicePage.Tech3Stach1',
                'ServicePage.Tech3Stach2',
                'ServicePage.Tech3Stach3',
                'ServicePage.Tech3Stach4',
                'ServicePage.Tech3Stach5',
                'ServicePage.Tech3Stach6',
            ],
            imageSrc: Tech3,
            backgroundColor: '#232323',
        },
        {
            id: 'digital-marketing',
            heading: 'MainPage.DigitalMarketing',
            description: 'ServicePage.Tech4Text',
            tags: [
                'ServicePage.Tech4Stach1',
                'ServicePage.Tech4Stach2',
                'ServicePage.Tech4Stach3',
                'ServicePage.Tech4Stach4',
                'ServicePage.Tech4Stach5',
            ],
            imageSrc: Tech4,
            backgroundColor: '#232323',
        },
        {
            id: 'embedded-systems',
            heading: 'MainPage.EmbeddedSystems',
            description: 'ServicePage.Tech5Text',
            tags: [
                'ServicePage.Tech5Stach1',
                'ServicePage.Tech5Stach2',
                'ServicePage.Tech5Stach3',
                'ServicePage.Tech5Stach4',
                'ServicePage.Tech5Stach5',
                'ServicePage.Tech5Stach6',
                'ServicePage.Tech5Stach7',
            ],
            imageSrc: Tech5,
            backgroundColor: '#232323',
        },
        {
            id: 'mechanical-design',
            heading: 'MainPage.MechanicalDesign',
            description: 'ServicePage.Tech6Text',
            tags: [
                'ServicePage.Tech6Stach1',
                'ServicePage.Tech6Stach2',
                'ServicePage.Tech6Stach3',
                'ServicePage.Tech6Stach4',
                'ServicePage.Tech6Stach5',
            ],
            imageSrc: Tech6,
            backgroundColor: '#232323',
        },
    ];

    return (
        <div className="tech-card-container">
            {techCards.map((card, index) => (
                <div
                    key={index}
                    id={card.id}
                    className="tech-card"
                    style={{ backgroundColor: card.backgroundColor }}
                >
                    <div className="tech-card-text">
                        <h2 className="tech-stack-heading">{t(card.heading)}</h2>
                        <p className="techstack-text">{t(card.description)}</p>
                        <div className="tech-tag-container">
                            {card.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className="tech-tag">
                                    {t(tag)}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="tech-card-image">
                        <img src={card.imageSrc} alt={t(card.heading)} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TechStack;